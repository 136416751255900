import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent, KenpDialog, ExpensesDialog, EstimatorDialog } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MatInputModule, MatIconModule, MatButtonModule, MatProgressBarModule, MatToolbarModule, MatCardModule, MatSlideToggleModule, MatNativeDateModule, MatSelectModule, MatAutocomplete, MatAutocompleteModule, MatDialogModule, MAT_DATE_LOCALE } from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSnackBarModule} from '@angular/material/snack-bar';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { DndDirective } from './dnd.directive';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NotFoundComponent,
    DndDirective,
    KenpDialog,
    EstimatorDialog,
    ExpensesDialog
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatInputModule,MatIconModule,MatButtonModule,MatProgressBarModule,MatIconModule, MatToolbarModule, MatCardModule, MatSlideToggleModule,
    MatDatepickerModule,MatTabsModule,MatSnackBarModule,
    MatNativeDateModule,MatTableModule, MatSelectModule, MatAutocompleteModule, MatDialogModule,
  ],
  providers: [CookieService, MatDatepickerModule],
  bootstrap: [AppComponent],
  entryComponents: [KenpDialog,EstimatorDialog, ExpensesDialog]
})
export class AppModule { }
