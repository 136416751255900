import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
const token:string="m1vCHDAYZ1kWmub7jwnEvyN06UQUif6i";
@Injectable({
  providedIn: 'root'
})
export class HandlerService {
  user:any={};
  API_BASE:string="https://kdp.cobaltfairy.online/api";

  expenses_suggestions:any;
  constructor(private http: HttpClient, private cookie:CookieService) { }

  getCookie(name){ return this.cookie.get(name); }
  setCookie(name,value,expires=1){ this.cookie.set(name,value,new Date().getDate() + expires); }

  setUser(u){this.user=u;}
  getUser(){return this.user;} 

  login(credentials){
    const headers = {'Content-Type': 'application/x-www-form-urlencoded'}
    const body = {
      service:'login',
      credentials:credentials
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }

  uploadTrackingNumbersExcel(data,metadata,url,token){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'insert_data',
      data:{
        url:url,
        token:token,
        metadata:metadata,
        data:data
      }
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }

  getExpensesByDate(timestamp){
    const headers = {'Content-Type': 'application/json'}
    const body = {}
    return this.http.get<any>('https://oracle.cobaltfairy.online/expenses.php?key=8899bcbd5e0ca49edcc11b486a0a53cd&action=get&bydate='+timestamp, {  });
  }
  getExpensesByQuery(query){
    const headers = {'Content-Type': 'application/json'}
    const body = {}
    return this.http.get<any>('https://oracle.cobaltfairy.online/expenses.php?key=8899bcbd5e0ca49edcc11b486a0a53cd&action=get&'+query, {  });
  }
  saveExpenses(data,removed_array){
    const headers = {'Content-Type': 'application/json'}
    return this.http.post<any>('https://oracle.cobaltfairy.online/expenses.php?key=8899bcbd5e0ca49edcc11b486a0a53cd&action=set', JSON.stringify({data:data,removed:removed_array}),  {headers:headers});
  }
  getExpensesSuggestions(){
    const headers = {'Content-Type': 'application/json'}
    return this.http.get<any>('https://oracle.cobaltfairy.online/expenses.php?key=8899bcbd5e0ca49edcc11b486a0a53cd&action=suggestions', {headers:headers});
  }
  postFileMonthRoy(file:File){
    let formData:FormData = new FormData();
        formData.append('fileToUpload', file, file.name);
        let headers_ = {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
        return this.http.post<any>("https://oracle.cobaltfairy.online/royalties-calc.php", formData)
  }
  postFileEstimator(file:File){
    let formData:FormData = new FormData();
        formData.append('fileToUpload', file, file.name);
        let headers_ = {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
        return this.http.post<any>("https://oracle.cobaltfairy.online/estimator.php", formData)
  }
  getGhostsTropes(){
    const headers = {'Content-Type': 'application/json'}
    return this.http.get<any>('https://oracle.cobaltfairy.online/expenses.php?key=8899bcbd5e0ca49edcc11b486a0a53cd&action=get_ghosts', {headers:headers});
  }
  getGhostSuggestions(){
    const headers = {'Content-Type': 'application/json'}
    return this.http.get<any>('https://oracle.cobaltfairy.online/get_writers.php?key=8899bcbd5e0ca49edcc11b486a0a53cd', {headers:headers});
  }
  postDatesToSubtract(first_date,last_date,kenp_rate,submit_to_oracle){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'subtract',
      first_date:first_date,
      last_date:last_date,
      kenp_rate:kenp_rate,
      submit_to_oracle:submit_to_oracle
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  postOneDate(date,kenp_rate,submit_to_oracle){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'compute_one_file',
      date:date,
      kenp_rate:kenp_rate,
      submit_to_oracle:submit_to_oracle
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  getBooks(){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'get_books'
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  updateBook(book){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'update_book',
      book:book
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  subtractMonth(month,kenp_rate){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'subtract_month',
      month:month,
      kenp_rate:kenp_rate
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  setOpt(key,value){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'set_opt',
      key:key,
      value:value
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }

  updateOptions(){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'get_opts'
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }

  getDates(){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'get_dates'
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }

  getKenpRates(){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'kenp',
      kenp_act:'get'
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  getKenpRatesPerStore(){
    return this.http.get("https://oracle.cobaltfairy.online/royalties-get.php");
  }
  postMLCSVToOracle(formdata:FormData){
    return this.http.post("https://oracle.cobaltfairy.online/ml_leadmagnets/endpoint.php?token=7c2f991bccb3ce755847d97b956ef2837",formdata);
  }


  setKenpRateOfMonth(month,kenp,id=-1){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'kenp',
      kenp_act:'set',
      kenp:kenp,
      kenp_id:id,
      month:month
    }
    console.log('body',body);
    // return null;
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  deleteKenpRateOfMonth(id=-1){
    const headers = {'Content-Type': 'application/json'}
    const body = {
      service:'handle_data',
      action:'kenp',
      kenp_act:'del',
      kenp_id:id
    }
    return this.http.post<any>(this.API_BASE+'/endpoint_v2.php?token='+token, body, { headers });
  }
  getRecurringExpenses(){
    const headers = {'Content-Type': 'application/json'}
    const body = { cfkey:'dummycheck' }
    return this.http.post<any>('https://oracle.cobaltfairy.online/expenses/api-recurring.php', body, { headers }).toPromise();
  }

}
