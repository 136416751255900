import { CookieService } from 'ngx-cookie-service';
import { HandlerService } from './../handler.service';
import { Component, ComponentFactoryResolver, Inject, OnInit, ɵSWITCH_TEMPLATE_REF_FACTORY__POST_R3__ } from '@angular/core';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2'

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {first, map, startWith} from 'rxjs/operators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';


interface Book{
  id:number,
  book_code:string,
  asin:string,
  release:string,
  genre:string,
  am:string,
  hard_launch:string,
  ghost:string,
  editor:string,
  kenpc:number,
  length:string,
  royalty:string,
  trope:string,
  xpromorecp:string,
  xpromoclcks:string,
  hm_recp:string,
  hm_opens:string,
  hm_clicks:string,
  index?:number
}
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class HomeComponent implements OnInit {
  version="3.2.5";
  waiting:boolean=false;
  currentTab:number=0;
  files: any[] = [];
  mlfiles: any[] = [];
  kenp_rates: any[] = [];
  default_kenp:any;
  kenp_rate = {kenp:'',time:''}
  allowedFileTypes = ['csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  arrayBuffer:any;
  thisfiledate:Date;
  url:string='kdp.cobaltfairy.online'
  token:string='7c2f991bccb3ce755847d97b956ef2837'
  xlsMetadata = {
    isMonthFile: false,
    update: false,
    date:'2020-11-08',
    kenp_rate:0.00
  }
  booklengthpattern = /([0-9]{2,4})k/;
  first_date:string="";
  last_date:string="";
  selectedBook:Book = {id:0,book_code:'',asin:'',release:'',genre:'',am:'',hard_launch:'',ghost:'',editor:'',kenpc:0,royalty:'',trope:'',xpromorecp:'',xpromoclcks:'',hm_recp:'',hm_opens:'',hm_clicks:'',length:''};
  selectedSoft:string="";
  selectedHard:string="";
  selectedBookAuthor:string = "";
  firebase:any;
  file_lock:boolean=false;

  ghostwriters:any[]=[];
  editors:any[]=[];
  ams:any[]=[];
  tropes:any[]=[];

  compute_month:boolean = false;
  compute_range:boolean = false;
  submit_to_oracle:boolean = true;

  expenses_row_date:string="";
  expenses_data:any[];
  removed_expenses:number[]=[];
  skip_expenses_index:number = -1;

  months = ['Ιαν','Φεβ','Μαρ','Απρ','Μαϊ','Ιον','Ιολ','Αυγ','Σεπ','Οκτ','Νοε','Δεκ'];

  public static AUTHORS:any = {
    HH: "Hanna Hamilton",
    EL: "Emma Linfield",
    LK: "Lydia Kendall",
    OB: "Olivia Bennet",
    GP: "GP Eliot",
    SO: "Scarlett Osborne",
    CH: "Cassidy Hanton",
    CM: "Clarice Mayfield",
    MM: "Maddie MacKenna",
    PH: "Patricia Haverton",
    VH: "Violet Hamers",
    EM: "Eloise Madigan",
    HL: "Hazel Linwood",
    FA: "Freya Atwood",
    TB: "Tiffany Baton",
    HC: "Harriet Caves",
    SV: "Sally Vixen",
    EK: "Ellis Keler",
    AH: "Amaya Henris",
    DB: "Daphne Byrne",
    MB: "Maybel Bardot",
    BL: "Belle Lovatt",
    RA: "Ruby Abbadon",
    AM: "Ava MacAdams"
  }
  public static GENRES:any = {
    HH: "CRR",
    EL: "CRR",
    LK: "SCO",
    OB: "SRR",
    GP: "",
    SO: "SRR",
    CH: "WHR",
    CM: "WHR",
    MM: "SCO",
    PH: "CRR",
    VH: "SRR",
    EM: "SCO",
    HL: "CRR",
    FA: "CRI",
    TB: "CRR",
    HC: "SRR",
    SV: "SRR",
    EK: "CRI",
    DB: "SRR",
    MB: "SRR",
    BL: "SRR",
    AH: "CRI",
    RA: "CRI",
    AM: "SRR"
  }
  total_revenue:number=0;
  units_revenue:number=0;
  ku_revenue:number=0;
  total_pages:number=0;
  total_units:number=0;
  recurrings:any=[];
  recurring_filters:any=[];
  recurring_dtOptions:any={};
  displayedColumns_recurring = [
    'Date','Content','Infrastructure','Payee','Platform','occurs','more_to_go','Notes','Action'
  ];
  displayedColumns: string[] = [
    'book_code', 
    'total_rev',
    'units_royal_USD', 
    'ku_royal_USD', 
    'pages', 
    'units', 
  ];
  displayedColumns_month: string[] = [
    'book_date', 
    'book_code', 
    'total_rev',
    'units_royal_USD', 
    'ku_royal_USD', 
    'pages', 
    'units', 
  ];
  expensesColumns: string[] = [
    'date',
    'author',
    'book',
    'content_',
    'editing',
    'promos',
    'payroll',
    'infrastructure',
    'bonus',
    'taxes',
    'payee',
    'trainee',
    'platform',
    'notes',
    'division',
    'url',
    'actions'
  ];
  dataSource = [];
  dataSource_month = [];
  dates_available = [];
  books = [];

  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  constructor(private router:Router,private service:HandlerService,private cookie:CookieService,public dialog: MatDialog, private _snackBar: MatSnackBar) { }


  showSnack(message: string, action: string, duration:number=5000){
    this._snackBar.open(message, action,{duration:duration});
  }

  ngOnInit() {
    if(!this.service.getCookie("user")){
      this.router.navigate(['/login']); 
      // this.service.setUser({user:{user:'',pass:''}})
      //   this.cookie.set('user',JSON.stringify({user:{user:'',pass:''}}))
      //   this.router.navigate(['/'])
    }else{
      this.showSnack("Loading components...","",1500)
    }
    this.service.updateOptions().subscribe(resp=>{
      if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'App Init',text:'Failed to update options: '+resp.message});return;}
      console.log(resp.data);
      if(resp.data.kenp_rate) this.xlsMetadata.kenp_rate = parseFloat(resp.data.kenp_rate);
    })
    // this.service.getKenpRates().subscribe(resp=>{
    //   if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'App Init',text:'Failed to update kenp rates: '+resp.message});return;}
    //   console.log('kenps',resp.data);
    //   if(resp.data){
    //     this.kenp_rates = resp.data;
    //     this.xlsMetadata.kenp_rate = this.kenp_rates[0].kenp;
    //   } 
    // })
    this.service.getKenpRatesPerStore().subscribe((resp:any)=>{
      if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'App Init',text:'Failed to update kenp rates: '+resp.message});return;}
      console.log('kenps',resp.data);
      if(resp.data){
        this.kenp_rates = resp.data;
        this.xlsMetadata.kenp_rate = this.kenp_rates[0].month;
      } 
    })
    this.service.getBooks().subscribe(resp=>{
      if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'Books data',text:'Failed to retrieve books: '+resp.message});return;}
      console.log(resp.data);
      if(resp.data) this.books = resp.data;
    });
    this.testRecurr();

    this.service.getDates().subscribe(resp=>{
      if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'App Init',text:'Failed to retrieve available dates: '+resp.message});return;}
      console.log(resp.data);
      this.dates_available =  resp.data;
      //TODO: apply limits only to available dates
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); 
      let yyyy = today.getFullYear();
      this.last_date = yyyy+"-"+mm+"-"+dd;
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      dd = String(yesterday.getDate()).padStart(2, '0');
      mm = String(yesterday.getMonth() + 1).padStart(2, '0'); 
      yyyy = yesterday.getFullYear();
      this.first_date = yyyy+"-"+mm+"-"+dd;
    })
    this.service.getGhostSuggestions().subscribe(sugg=>{
      if(!sugg.am.includes('asta'))sugg.am.push('asta');
      if(!sugg.am.includes('ekap'))sugg.am.push('ekap');
      if(sugg.am) this.ams = sugg.am;
      if(sugg.editors) this.editors = sugg.editors;
      // if(sugg.ghost) this.ghostwriters = sugg.ghost;
    })
    this.service.getExpensesSuggestions().subscribe(resp=>{
      this.showSnack("Received expenses suggestions","OK");
      console.log(resp);
      if(resp.data) this.service.expenses_suggestions = resp.data;
      if(resp.data.ghosts) this.ghostwriters = resp.data.ghosts;
      if(resp.data.tropes) this.tropes = resp.data.tropes;
    });
    
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );



  }
  kenpChanged($event){
    if($event.value) this.xlsMetadata.kenp_rate=$event.value;
    else Swal.fire({icon:'warning',title:'KENP rate',text:'Could not assign a kenp value.'});
  }
  openKenpDialog(krate={}) {
    const dialogRef = this.dialog.open(KenpDialog,{ height: 'auto', width: '600px', data:krate});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result.success){
        this.service.getKenpRates().subscribe(resp=>{
          if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'App Init',text:'Failed to update kenp rates: '+resp.message});return;}
          console.log('kenps',resp.data);
          if(resp.data){
            this.kenp_rates = resp.data;
            this.xlsMetadata.kenp_rate = this.kenp_rates[0].kenp;
          } 
        })
      }
    });
  }
  openEstimatorDialog(krate={}) {
    const dialogRef = this.dialog.open(EstimatorDialog,{ height: 'auto', width: '600px', data:krate});
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if(result.success){

      }
    });
  }
  xpromos(){
    window.open("https://oracle.cobaltfairy.online/mlcrawl.php");
  }
  clearBar(){
    if(this.selectedBook) this.saveCurrentBook();
    (<any>document.querySelector("#bar")).value="";
    this.myControl.setValue("");
    this.selectedBook={id:0,book_code:'',asin:'',release:'',genre:'',am:'',hard_launch:'',ghost:'',editor:'',kenpc:0,royalty:'',trope:'',xpromorecp:'',xpromoclcks:'',hm_recp:'',hm_opens:'',hm_clicks:'',length:''};
    this.selectedBookAuthor="";
  }
  date_of_kenp(date){
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let dt = new Date(date.split(' ')[0].toString());
    return monthNames[dt.getMonth()] +"-"+dt.getFullYear();
  }
  edit_kenp($event,krate){
    console.log($event);
    console.log(krate);
    $event.stopPropagation();
    this.openKenpDialog(krate);
  }
  bookSelected(book){
    if(this.selectedBook) this.saveCurrentBook();
    let found = false;
    this.books.forEach(b=>{
      if(book==b.book_code){
        this.selectedBook = {id:b.id,book_code:b.book_code,asin:b.asin,release:b.release,genre:b.genre||'',am:b.am||'',hard_launch:b.hard_launch||'',ghost:b.ghost||'',editor:b.editor||'',kenpc:b.kenpc||0,length:b.length||'',royalty:b.royalty||'',trope:b.trope||'',xpromorecp:b.xpromorecp||'',xpromoclcks:b.xpromoclcks||'',hm_recp:b.hm_recp||'',hm_opens:b.hm_opens||'',hm_clicks:b.hm_clicks||'',};
        let date = new Date(b.release);
        this.selectedSoft = date.toLocaleDateString().split("/")[0] + " "+this.months[date.getMonth()]+" " + date.toLocaleDateString().split("/")[2];
        date = new Date(b.hard_launch);
        this.selectedHard = date.toLocaleDateString().split("/")[0] + " "+this.months[date.getMonth()]+" " + date.toLocaleDateString().split("/")[2];
        let CODE = b.book_code.toString().split('-')[1].trim().substr(0,2);
        this.selectedBookAuthor = HomeComponent.AUTHORS[CODE];
        this.selectedBook.genre = HomeComponent.GENRES[CODE]
        found=true;
     }
    });
    if(!found) alert('Failed to locate book '+book);
  }
  newBookCode(){
    let CODE = this.selectedBook.book_code.toString().split('-')[1].trim().substr(0,2);
    this.selectedBook.genre = HomeComponent.GENRES[CODE];
    console.log(this.selectedBook);
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase().replace(" ","");
    return this.books.filter(book => book.book_code.toLowerCase().replace(" ","").includes(filterValue));
  }
  logout(){
    this.cookie.delete('user')
    this.service.setUser({});
    window.location.reload(); 
  }

  dateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
   let date = new Date(event.value);
   let dd = String(date.getDate()).padStart(2, '0');
      let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = date.getFullYear();
      this.xlsMetadata.date = yyyy+"-"+mm+"-"+dd;
    console.log(this.xlsMetadata.date);
  }
  releaseChanged(type: string, event: MatDatepickerInputEvent<Date>) {
   let date = new Date(event.value);
   let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    this.selectedBook.release = yyyy+"-"+mm+"-"+dd;
    this.selectedSoft = date.toLocaleDateString().split("/")[0] + " "+this.months[date.getMonth()]+" " + date.toLocaleDateString().split("/")[2];
    console.log(this.selectedBook);
  }
  hard_launchChanged(type: string, event: MatDatepickerInputEvent<Date>) {
   let date = new Date(event.value);
   let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    this.selectedBook.hard_launch = yyyy+"-"+mm+"-"+dd;
    this.selectedHard = date.toLocaleDateString().split("/")[0] + " "+this.months[date.getMonth()]+" " + date.toLocaleDateString().split("/")[2];
    console.log(this.selectedBook);
  }

  expensesNewLine(quickfix=false){
    if(!this.expenses_row_date && !quickfix){
      alert("Please select a date first.");
      return;
    }
    if(!this.expenses_data) this.expenses_data=[];
    this.expenses_data.push({Date: this.expenses_row_date , Author: '',Book: '',Content_: '',Editing: '',Promos: '',Payroll: '',Infrastructure: '',Taxes: '',Payee: '',Platform: '',Notes: '',Division: ''});
    this.expenses_data = JSON.parse(JSON.stringify(this.expenses_data));
    if(!quickfix)this.editExpenses(this.expenses_data[this.expenses_data.length-1])
  }
  expensesRemove(idx,row_id){
    console.log(idx)
    if(idx>=0 && idx<=this.expenses_data.length-1){
      this.removed_expenses.push(row_id);
      this.expenses_data.splice(idx,1);
      console.log('sliced',this.removed_expenses)
    }
    this.expenses_data = JSON.parse(JSON.stringify(this.expenses_data))
    this.expenses_data.slice();
  }
  async testRecurr(){
    let resp = await this.service.getRecurringExpenses();
    this.recurrings = resp.data;
  }
  searchRecurrExpense(recurr){
    let query = [];
    if(recurr.Payee==null) recurr.Payee="";
    if(recurr.Platform==null) recurr.Platform="";
    if(recurr.Notes && recurr.Notes.length>0) query.push("p_Notes="+recurr.Notes);
    if(recurr.Platform && recurr.Platform.length>0) query.push("p_Platform="+recurr.Platform);
    if(recurr.Payee && recurr.Payee.length>0) query.push("p_Payee="+recurr.Payee);
    if(recurr.Content && recurr.Content.toString().length>0) query.push("p_Content="+recurr.Content.toString());
    if(recurr.Infrastructure && recurr.Infrastructure.toString().length>0) query.push("p_Infrastructure="+recurr.Infrastructure.toString());
  
    let queryString = query.join('&');
    // queryString = encodeURIComponent(queryString)
    console.log('querystring',queryString)
    this.service.getExpensesByQuery(queryString).subscribe((resp:any)=>{
      if(resp.code==200){
        this.expenses_data = resp.data
        this.currentTab=4;
        setTimeout(()=>{
          this.expensesNewLine(true);
          setTimeout(()=>{
            this.expenses_data = this.expenses_data.slice(0,this.expenses_data.length-1);
            console.log(this.expenses_data)
            if(this.expenses_data.length==100){
              Swal.fire({
                icon:'warning',
                title:'Too many rows found!',
                text:'It seems that too many results have been found, and only 100 are displayed to avoid the application crashing.'
              })
            }
          },100);
        },200);
      }
    })
  }
  formatRecurrDates(datescomma){
    return datescomma.join('<br>');
  }
  saveExpenses(){
    let expenses_data = null
    if(this.skip_expenses_index>-1){
      expenses_data = this.expenses_data.slice(this.skip_expenses_index);
    }else{
      expenses_data = this.expenses_data;
    }
    console.log('new data',expenses_data, this.expenses_data)
    Swal.fire({icon:'info',title:'Submit changes?',text:'Caution: Changes cannot be undone automatically.',showCancelButton:true,showConfirmButton:true,cancelButtonText:'Cancel',confirmButtonText:'Yes, submit'}).then((res:any)=>{
      if(res.value){
        this.waiting=true;
        this.service.saveExpenses(expenses_data,this.removed_expenses).subscribe((res:any)=>{
          console.log(res);
          this.waiting=false;
          if(res.code && res.code==200){
            Swal.fire({icon:'success',timer:800}).then(()=>{window.location.reload()});

          }else{
            if(res.code==507){ //means it broke on a specific index. skip everything before that index.
              this.skip_expenses_index = res.data[0];
            }
            Swal.fire({
              icon:'error',
              title:'Query failed:',
              text:res.message
            })
          }
        },(err:any)=>{
          this.waiting=false;
          console.log(err);
          if(err.code==507){ //means it broke on a specific index. skip everything before that index.
            alert("Skip after "+err.data[0]);
            this.skip_expenses_index = err.data[0];
          }
          this.showSnack("Please check developer's console. Some rows were possibly broken.","",10000);
        })
      }
    })
    
  }
  subDateToday(){
    this.subDateChanged('expenses_row_date',{value:new Date().toISOString().split('T')[0]});
  }
  subDateYesterday(){
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate()-1)
    this.subDateChanged('expenses_row_date',{value:yesterday.toISOString().split('T')[0]});
  }
  subDateChanged(type: string, event: any) {
    this.skip_expenses_index=-1;
   let date = new Date(event.value);
   console.log(date);
   let dd = String(date.getDate()).padStart(2, '0');
   let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
   let yyyy = date.getFullYear();
   switch(type){
     case "first_date": this.first_date = yyyy+"-"+mm+"-"+dd; break;
     case "last_date": this.last_date = yyyy+"-"+mm+"-"+dd; break;
     case "expenses_row_date": 
      this.expenses_row_date = yyyy+"-"+mm+"-"+dd;
      this.waiting=true;
      this.service.getExpensesByDate(date.getTime()/1000).subscribe((resp:any)=>{
        this.waiting=false;
        console.log(resp);
        if(resp.code==200){
          this.expenses_data = resp.data
          setTimeout(()=>{
            this.expensesNewLine(true);
            setTimeout(()=>{
              this.expenses_data = this.expenses_data.slice(0,this.expenses_data.length-1);
              console.log(this.expenses_data)
            },100);
          },200);
        }
      })
      break;
    default :alert('Failed to change date. Please refresh this page.');
   }
   console.log('expenses_row_date',this.expenses_row_date)
  }
  showSearchForm(){
    const dialogRef = this.dialog.open(ExpensesDialog,{ height: 'auto', width: '1024px', data:{isSearch:true}});

    dialogRef.afterClosed().subscribe(result => {
      console.log('search for:',result);
      if(result && result.search){
        let query = [];
        Object.keys(result.search).forEach((o,i)=>{
          if(result.search[o]!=undefined) query.push("p_"+Object.keys(result.search)[i]+"="+result.search[o]);
        })
        console.log('querystring',query.join('&'))
        this.service.getExpensesByQuery(query.join('&')).subscribe((resp:any)=>{
          if(resp.code==200){
            this.expenses_data = resp.data
            setTimeout(()=>{
              this.expensesNewLine(true);
              setTimeout(()=>{
                this.expenses_data = this.expenses_data.slice(0,this.expenses_data.length-1);
                console.log(this.expenses_data)
                if(this.expenses_data.length==100){
                  Swal.fire({
                    icon:'warning',
                    title:'Too many rows found!',
                    text:'It seems that too many results have been found, and only 100 are displayed to avoid the application crashing.'
                  })
                }
              },100);
            },200);
          }
        })
      }else{
        Swal.fire({icon:'info',title:'No terms selected',timer:1000})
      }
    });
  }
  onTabChanged(event){
    if(event.index==1){
      this.service.getDates().subscribe(resp=>{
        if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'App Init',text:'Failed to retrieve available dates: '+resp.message});return;}
        console.log(resp.data);
        this.dates_available =  resp.data;
        this.first_date = this.getDay(-1);
        this.last_date = this.getDay();
        console.log(this.last_date,this.first_date)
      });
    }
    this.currentTab=event.index;
  }

  async parseXLSX(){
    if(this.waiting){
      console.log("Aborting due to another running upload.");
      return;
    }
  this.waiting=true;
  let fileReader = new FileReader();  
  this.file_lock = false;  
  for(let findex=0;findex<this.files.length;findex++) {
    while(this.file_lock) {
      console.log('sleep 1s while locked.');
      await this.sleep(2000);
    }
    this.file_lock=true;
    fileReader = new FileReader();  
    fileReader.readAsArrayBuffer(this.files[findex]);  
    console.log("Now parsing file "+this.files[findex].name);  
    fileReader.onload = async (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});        
      let index = -1;
        workbook.SheetNames.forEach((v:any,i:number)=>{
          if(v.includes('eBook Royalty')) index = i;
        })
      var ebook_royalty_name = workbook.SheetNames[index];
      var ebook_royalty = workbook.Sheets[ebook_royalty_name];   
      var range = XLSX.utils.decode_range(ebook_royalty['!ref']);
      range.s.r = 0; // <-- zero-indexed, so setting to 1 will skip row 0
      range.e.r = 10000; // <-- zero-indexed, so setting to 1 will skip row 0
      ebook_royalty['!ref'] = XLSX.utils.encode_range(range);   
      let xlsxfile = XLSX.utils.sheet_to_json(ebook_royalty,{raw:false,range:range})
      
     
      let books = [];
      // if(!xlsxfile[0] || !xlsxfile[0]['Royalty Date']){
      //   Swal.fire({icon:'warning',text:'Could not verify the "Royalty Date" of this file.'})
      // }else{
        let firstdate = new Date(Date.parse(xlsxfile[0]['Royalty Date']));
        let dd = String(firstdate.getDate()).padStart(2, '0');
        let mm = String(firstdate.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = firstdate.getFullYear();
        this.xlsMetadata.date = yyyy+"-"+mm+"-"+dd;
        // if(firstdate.getTime()!=(new Date(Date.parse(this.xlsMetadata.date))).getTime()){
        //   Swal.fire({icon:'warning',text:'Dates mismatch'})
        //   return;
        // }
      //   let lastdate = new Date(Date.parse(xlsxfile[xlsxfile.length-1]['Royalty Date']));
      //   if(firstdate.getTime() != lastdate.getTime()){
      //     Swal.fire({icon:'warning',text:'This file contains data for more than one date.'})
      //   }
      //   if(firstdate.getTime() != this.thisfiledate.getTime()){
      //     Swal.fire({icon:'warning',text:'The filename\'s date does not match with the date inside the file.'})
      //   }
      // }

      xlsxfile.forEach((excel_row,index) => {
        books.push({
          // ASIN:excel_row['ASIN/ISBN'],
          ASIN:excel_row['ASIN'],
          author_name:excel_row['Author Name'],
          currency:excel_row['Currency'],
          marketplace:excel_row['Marketplace'],
          net_units_sold:0,//parseInt(excel_row['Net Units Sold']),
          royalty:excel_row['Royalty'],
          royalty_type:excel_row['Royalty Type'],
          trans_type:excel_row['Transaction Type'],
          units_sold:0,//parseInt(excel_row['Units Sold']),
          units_refunded:0,//parseInt(excel_row['Units Refunded']),
          kenp:0
        })
      });
      var kenp_read_name = workbook.SheetNames[workbook.SheetNames.length-1]; // was 4 before adding "hardcover" workbook, index 5 after 14Sep2021
      var kenp_read = workbook.Sheets[kenp_read_name];   
      range = XLSX.utils.decode_range(kenp_read['!ref']);
      range.s.r = 0; // <-- zero-indexed, so setting to 1 will skip row 0
      range.e.r = 10000; // <-- zero-indexed, so setting to 1 will skip row 0
      kenp_read['!ref'] = XLSX.utils.encode_range(range);   
      let xlsxfile_read = XLSX.utils.sheet_to_json(kenp_read,{raw:false,range:range})
      let kenp = [];
      xlsxfile_read.forEach((excel_row,index) => {
        let kenp_str = 'Kindle Edition Normalized Pages (KENP) Read from KU and KOLL';
        if(!excel_row[kenp_str]) kenp_str = 'Kindle Edition Normalized Page (KENP) Read';
        let that = { ASIN:excel_row['ASIN'], marketplace:excel_row['Marketplace'], kenp:parseFloat(excel_row[kenp_str].replace(',','')), author_name:excel_row['Author Name'], currency:'N/A', net_units_sold:0,units_refunded:0, royalty:0 };
        let book_index = -1;
        books.forEach((book,index)=>{
          if(that.ASIN == book.ASIN && that.marketplace == book.marketplace){
            book_index=index;
          } 
        })

        if(book_index>=0){
          books[book_index].kenp = that.kenp;
        }else{
          books.push(that)
        }
      });

      var orders_name = workbook.SheetNames[workbook.SheetNames.length-2]; //Orders sheet, after 14Sep2021 is in index 4 !!
      var orders_sheet = workbook.Sheets[orders_name];   
      range = XLSX.utils.decode_range(orders_sheet['!ref']);
      range.s.r = 0; // <-- zero-indexed, so setting to 1 will skip row 0
      range.e.r = 10000; // <-- zero-indexed, so setting to 1 will skip row 0
      orders_sheet['!ref'] = XLSX.utils.encode_range(range);   
      let xlsxfile_orders = XLSX.utils.sheet_to_json(orders_sheet,{raw:false,range:range})
      let orders = [];
      let free_uns = 0;
      xlsxfile_orders.forEach((excel_row,index) => {
        let that = { ASIN:excel_row['ASIN'], marketplace:excel_row['Marketplace'], net_units_sold:parseFloat((''+excel_row['Paid Units']).replace(',','')), free_units:parseFloat((''+excel_row['Free Units']).replace(',','')), kenp:0, units_royalty:0, currency: 'N/A' };
        let book_index = -1;
        books.forEach((book,index)=>{
          if(that.ASIN == book.ASIN && that.marketplace == book.marketplace){
            book_index=index;
          } 
        })
        if(book_index>=0){
          books[book_index].units_sold = that.net_units_sold;
          books[book_index].net_units_sold = that.net_units_sold;
          books[book_index].free_units = that.free_units;
        }else{
          books.push(that)
        }
      });

      if(books.length>0){
        this.service.uploadTrackingNumbersExcel(books,this.xlsMetadata,this.url,this.token).subscribe(response=>{
          if(response.code){
              this.file_lock= false;
              switch(response.code){
                case 200:
                  Swal.fire({icon:'success',text:'File '+this.files[findex].name+' uploaded: '+response.message,timer:500})
                  break;
                default:
                  Swal.fire({icon:'warning',text:'Request failed: '+response.message})
              }
            }else{
              this.file_lock= false;
              console.log(response);
              Swal.fire({icon:'error',text:'API call failed. See developer\'s console for details.'})
            }
            if(findex==this.files.length-1){
              this.files=[];
              this.waiting=false;
            }
        })
        await this.sleep(2000);
      }else{
        this.waiting=false;
        Swal.fire({icon:'error',text:'Could not read books from file.'})
      }
    } 
  }
  /*
  fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});        
      var sheet_name = workbook.SheetNames[3]; // = third worksheet
      var worksheet = workbook.Sheets[sheet_name];   
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
      range.e.r = 10000; // <-- zero-indexed, so setting to 1 will skip row 0
      worksheet['!ref'] = XLSX.utils.encode_range(range);   
      let xlsxfile = XLSX.utils.sheet_to_json(worksheet,{raw:true,range:range});
      let books = [];
      xlsxfile.forEach((excel_row,index) => {
        books.push(excel_row);
      });
      if(books.length>0){
        this.service.uploadTrackingNumbersExcel(books,this.xlsMetadata,this.url,this.token).subscribe(response=>{
            this.waiting=false;
            if(response.code){
              switch(response.code){
                case 200:
                  Swal.fire({icon:'success',text:'File uploaded: '+response.message})
                  this.files=[];
                  break;
                default:
                  Swal.fire({icon:'warning',text:'Request failed: '+response.message})
              }
            }else{
              console.log(response);
              Swal.fire({icon:'error',text:'API call failed. See developer\'s console for details.'})
            }
         })
      }else{
        this.waiting=false;
        Swal.fire({icon:'error',text:'Could not read books from file.'})
      }
  } 
  */
  }
  update_kenp(){
    this.service.setOpt('kenp_rate',this.xlsMetadata.kenp_rate).subscribe(resp=>{
      console.log('SET OPT RESULT',resp);
      if(resp.code==200){
        Swal.fire({icon:'success',timer:500})
      }else{
        Swal.fire({icon:'error',text:resp.message})
      }
    })
  }
  sleep = ms => new Promise(r => setTimeout(r, ms));

  async subtract(){
    console.log('this.xlsMetadata.kenp_rate',this.xlsMetadata.kenp_rate)
    console.log('this.kenp_rate',this.kenp_rate)
    console.log('first_day',this.first_date)
    if(this.submit_to_oracle){
      let result =  await Swal.fire({icon:'warning',text:'After computations, results will be auto-submitted to the Oracle Database. Continue?',confirmButtonText:'OK',cancelButtonText:'Cancel',showConfirmButton:true,showCancelButton:true});
      if(!result.value) return;
    }
    this.waiting=true;
    if(!this.compute_range){
      console.log('fist_date',this.first_date)
      this.service.postOneDate(this.first_date,this.xlsMetadata.kenp_rate,this.submit_to_oracle).subscribe(resp=>{
        console.log(resp);
          if(!resp.code || resp.code!=200){
            Swal.fire({icon:'error',text:'Request failed: '+resp.message});
            this.waiting=false;
            return;
          }
          this.dataSource = resp.data;
          let ku_royal_USD = 0;
          let units_royal_USD = 0;
          let total_rev = 0;
          let total_pag = 0;
          let total_uni = 0;
          resp.data.forEach(element => {
              if(undefined!==element.ku_royal_USD) ku_royal_USD += parseFloat(element.ku_royal_USD)
              if(undefined!==element.units_royal_USD)  units_royal_USD += parseFloat(element.units_royal_USD)
              if(undefined!==element.total_rev) total_rev += parseFloat(element.total_rev)
              total_pag += (element.pages)
              total_uni += (element.units)
            
          });      

          this.total_revenue=total_rev;
          this.units_revenue=units_royal_USD;
          this.ku_revenue=ku_royal_USD;
          this.total_pages=total_pag;
          this.total_units=total_uni;
          console.log('total_rev',total_rev)
          console.log('units_royal_USD',units_royal_USD)
          console.log('ku_royal_USD',ku_royal_USD)
            Swal.fire({icon:'success',timer:500}).then(()=>{
            this.currentTab=2;
          });
        });
        this.waiting=false;
        return;
    }
    // //fiurst day should be yesterdays Date() in previous month.
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let mm1 = String(yesterday.getMonth()).padStart(2, '0'); 
    let yyyy1 = yesterday.getFullYear();
    this.first_date = yyyy1+'-'+mm1+'-01';
    //Assume last_date is the last day of the seleced month.
    let rdate = new Date(this.first_date)
    let lastDay = new Date(rdate.getFullYear(), rdate.getMonth() + 1, 0);
    let dd = String("15").padStart(2, '0');
    let mm = String(lastDay.getMonth() + 2).padStart(2, '0'); 
    let yyyy = lastDay.getFullYear();
    if(new Date().getDate()<=15){
      dd = String(new Date().getDate()-1).padStart(2,'0');
    }
    if(mm=="13"){
      mm="01";
      yyyy++;
    }
    this.last_date = yyyy+'-'+mm+'-'+dd;
    // let temp = new Date(yyyy,parseInt(mm),0);
    // this.last_date = temp.getFullYear()+"-"+String((temp.getMonth()+1)).padStart(2,'0')+"-"+temp.getDate();
  
    console.log(this.first_date,this.last_date); 
    this.service.postDatesToSubtract(this.first_date,this.last_date,this.xlsMetadata.kenp_rate,this.submit_to_oracle).subscribe(resp=>{
    console.log(this.first_date,this.last_date,resp);
      if(!resp.code || resp.code!=200){
        Swal.fire({icon:'error',text:'Request failed: '+resp.message});
        this.waiting=false;
        return;
      }
      // this.dataSource_month = [];
      //   resp.data.keys.forEach(key => {
      //     resp.data.dates[key].forEach(d => {
      //       d['book_date'] = key;
      //       this.dataSource_month.push(d);
      //     });
      //   });
      //   console.log('month!',this.dataSource_month)
        Swal.fire({icon:'success',text:"Date range computations running in the background. You will be notified by email when finished."}).then(()=>{
          // this.currentTab=2;
          // this.waiting=false;
          // setTimeout(()=>{
          //   // this.tableToExcel('month-data');
          //   window.open("https://kdp.cobaltfairy.online/api/download_in_range.php?key="+btoa(this.first_date+'_'+this.last_date))
          // },100)
        });
       
    });
    
  }

  date_get_month_year(date){
    let monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    let d = new Date(date);
    return monthNames[d.getMonth()] + " "+d.getFullYear();
  }

  getTotalRevenue(){}
  getTotalKURevenue(){}
  getTotalPages(){}
  getTotalUnits(){}


  editExpenses(row) {
    if(!row.Content_)row.Content_=0;
    if(!row.Bonus)row.Bonus=0;
    if(!row.Editing)row.Editing=0;
    if(!row.Infrastructure)row.Infrastructure=0;
    if(!row.Payroll)row.Payroll=0;
    if(!row.Promos)row.Promos=0;
    if(!row.Taxes)row.Taxes=0;
    if(!row.Genre)row.Genre='';
    if(row.recurr && (row.recurr=="true" || row.recurr=="1")) row.recurr=true; else row.recurr=false;
    const dialogRef = this.dialog.open(ExpensesDialog,{ height: 'auto', width: '1024px', data:row});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result:`,result,row);
      delete(row.datefrom)
      delete(row.dateto)
      delete(row.isSearch)
    });
  }

  numberFormat(number){
    if(number==undefined) return "0.00";
    return number;
  }
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  onMLFileDropped($event) {
    this.mlfiles = $event;
    // this.prepareFilesList($event);
  }
  fileMLBrowseHandler(files) {
    this.mlfiles = files;
    // this.prepareFilesList(files);
  }
  deleteMLFile(index: number) {
    this.mlfiles.splice(index, 1);
  }
  uploadMLCSV(){
    let filename = this.mlfiles[0].name;

    const formData = new FormData();

    formData.append("csv", this.mlfiles[0]);
    this.waiting=true;
    this.service.postMLCSVToOracle(formData).subscribe((resp)=>{
      this.waiting=false;
      console.log(resp);
      Swal.fire({icon:'success',text:'Thank you!',timer:1500})
      this.mlfiles=[];
    })
  }
  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>,findex=0) {
    for (const item of files) {
      item.progress = 0;
      if(!this.allowedFileTypes.includes(item.type)){
        Swal.fire({icon:'warning',text:'Not supported file. Only xls files allowed.'})
        return;
      }
      this.files.push(item);
    }
    let filename = this.files[findex];
    let isMonth = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{13}");
    let isDay = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}");
    try{
      const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
      let filedate = filename.name.match(/(\d{2}\-\d{2}\-\d{2})/)[0];
      let dt = new Date(Date.parse('20'+filedate));
      this.thisfiledate = dt;
      console.log(filedate,dt)
      // Swal.fire({icon:'warning','text':"File's date: "+dt.getDate()+" "+monthNames[dt.getMonth()]+" "+dt.getFullYear()})
      let fileReader = new FileReader();    
      fileReader.readAsArrayBuffer(this.files[0]);    
      fileReader.onload = (e) => {    
        this.arrayBuffer = fileReader.result;    
        var data = new Uint8Array(this.arrayBuffer);    
        var arr = new Array();    
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
        var bstr = arr.join("");    
        var workbook = XLSX.read(bstr, {type:"binary"});        
        let index = -1;
        workbook.SheetNames.forEach((v:any,i:number)=>{
          if(v.includes('Combined Sales')) index = i;
        })
        var ebook_royalty_name = workbook.SheetNames[index];
        var ebook_royalty = workbook.Sheets[ebook_royalty_name];   
        var range = XLSX.utils.decode_range(ebook_royalty['!ref']);
        range.s.r = 0; // <-- zero-indexed, so setting to 1 will skip row 0
        range.e.r = 10000; // <-- zero-indexed, so setting to 1 will skip row 0
        ebook_royalty['!ref'] = XLSX.utils.encode_range(range);   
        let xlsxfile = XLSX.utils.sheet_to_json(ebook_royalty,{raw:false,range:range})
        let books = [];
        console.log('xlsxfile',xlsxfile);
        if(!xlsxfile[0] || !xlsxfile[0]['Royalty Date']){
          Swal.fire({icon:'warning',text:'Could not verify the "Royalty Date" of this file.'})
        }else{
          let firstdate = new Date(Date.parse(xlsxfile[0]['Royalty Date']));
          // this.xlsMetadata.date = firstdate.getFullYear()+"-"+(firstdate.getMonth()+1)+"-"+firstdate.getDate();
          let dd = String(firstdate.getDate()).padStart(2, '0');
          let mm = String(firstdate.getMonth() + 1).padStart(2, '0'); //January is 0!
          let yyyy = firstdate.getFullYear();
          this.xlsMetadata.date = yyyy+"-"+mm+"-"+dd;
          console.log('Date found: ',this.xlsMetadata.date)
          // alert(firstdate.getFullYear()+"-"+(firstdate.getMonth()+1)+"-"+firstdate.getDate());
          // let lastdate = new Date(Date.parse(xlsxfile[xlsxfile.length-1]['Royalty Date']));
          // if(firstdate.getTime() != lastdate.getTime()){
          //   Swal.fire({icon:'warning',text:'This file contains data for more than one dates.'})
          // }
          // if(firstdate.getTime() != this.thisfiledate.getTime()){
          //   Swal.fire({icon:'warning',text:'The filename\'s date does not match the date inside the file.'})
          // }
        }
      }
    }catch(e){
      console.log(e);
      Swal.fire({icon:'warning','text':"Could not identify date format in the file's name."})
    }
  
    // 
    if(isMonth.test(filename.name)){ // month file
      let date = filename.name.match(/[0-9]{4}-[0-9]{2}-[0-9]{13}/g)[0];
      date = date.split("-");
      date[2] = "01";
      console.log(date);
      this.xlsMetadata.date = date.join("-");
      alert('IS MONTH')
    }else if(isDay.test(filename.name)){ // day file
      // this.xlsMetadata.date = filename.name.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g)[0]
      // alert('IS DAY')
    }else{
      alert('NEITHER DAY NOR MONTH')
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = today.getFullYear();
      this.xlsMetadata.date = yyyy+"-"+mm+"-"+dd;
    }
    //this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals=2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  tableToExcel(type){
    var uri = 'data:application/vnd.ms-excel;base64,'
    let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>';
    (<any>document.getElementById("dlink")).href = uri + this.base64(this.format(template, { worksheet: 'KDP Data '+this.last_date+' - '+this.last_date, table: document.querySelector((type=="my-data")?"#my-data":"#month-data").innerHTML }));
    (<any>document.getElementById("dlink")).download = (type=="my-data") ? "export_"+this.first_date+'_'+this.last_date+".xls" : "export_month.xls";
    (<any>document.getElementById("dlink")).click();
  }

  base64(s){
    return window.btoa(unescape(encodeURIComponent(s)))
  }
  format(s,c){
    return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
  }
  getDay(index=0){
    let today = new Date();
    today.setDate(today.getDate() + index);
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    return yyyy+"-"+mm+"-"+dd;
  }
  selectTableContents() {
    let el = document.querySelector('tbody');
    var body = document.body, range, sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if ((<any>body).createTextRange) {
      range = (<any>body).createTextRange();
      range.moveToElementText(el);
      range.select();
    }
  }
  updateBook(){
    this.saveCurrentBook()
    console.log(this.books);
    let canpush = true;
    this.books.forEach(book=>{
      if(book.asin.length<1 || book.book_code.length<3){
        canpush = false;
        Swal.fire({icon:'warning',text:'Please make sure there\'s no empty ASIN or Book Code.'});
      }
      console.log(book.length, this.booklengthpattern.test(book.length))
      if(! (this.booklengthpattern.test(book.length)) && "n/a"!=book.length){
        canpush = false;
        Swal.fire({icon:'warning',text:'Please make sure you tyepd all edited book Length fields to the proper format.'});
      }
    })
    if(!canpush)return;
    this.service.updateBook(this.books).subscribe(resp=>{
      if(!resp.code || resp.code!=200){Swal.fire({icon:'warning',title:'Error',text:'Failed to update books: '+resp.message});return;}
      console.log(resp);
      if(resp.code==200){
        Swal.fire({icon:'success',title:'Updated',timer:500})
        setTimeout(()=>{window.location.reload()},900)
      }
    });
  }
  addBook(){
    this.saveCurrentBook();
    this.clearBar();
    this.books.push({id:-1,asin:'',book_code:(parseInt(this.books[this.books.length-1].book_code.split('-')[0])+1)+' - ',index:this.books.length});
    let b = this.books[this.books.length-1];
    this.selectedBook = {id:b.id,book_code:b.book_code,asin:b.asin,release:b.release,genre:b.genre,am:b.am,hard_launch:b.hard_launch,ghost:b.ghost,editor:b.editor,kenpc:b.kenpc,length:b.length,royalty:b.royalty,trope:b.trope,xpromorecp:b.xpromorecp,xpromoclcks:b.xpromoclcks,hm_recp:b.hm_recp,hm_opens:b.hm_opens,hm_clicks:b.hm_clicks,index:b.index};
  }
  addWriter(){
    if(this.ghostwriters.length<1){
      this.showSnack("Please wait for components to load..","OK",4000);
      return;
    }
    let writer = (<any>window).prompt('Type a new Ghostwriter\'s / Editor\'s name: ');
    this.ghostwriters.push(writer)
    this.editors.push(writer)
  }
  addAM(){
    if(this.ams.length<1){
      this.showSnack("Please wait for components to load..","OK",4000);
      return;
    }
    let newam = (<any>window).prompt('Type a new AM\'s name: ');
    this.ams.push(newam);
    alert("Added "+newam+" to available AMs (this will not affect the database until you hit save.)");
  }
  addTrope(){
    if(this.tropes.length<1){
      this.showSnack("Please wait for components to load..","OK",4000);
      return;
    }
    let nwtrope = (<any>window).prompt('Type a new Trope: ');
    this.tropes.push(nwtrope);
    alert("Added "+nwtrope+" to available Tropes (this will not affect the database until you hit save.)");
  }
  saveCurrentBook(){
    if(this.selectedBook.id && this.selectedBook.id>0){
      let selected = this.selectedBook;
      let books = this.books;
      this.books.forEach((book,i)=>{
        if(book.id == selected.id){
          books[i] = selected;
        }
      })
      this.books=books;
    }
    if(this.selectedBook.id<0 && this.selectedBook.index){ //save the latest changes.
      this.books[this.selectedBook.index] = this.selectedBook;
    }
  }
}

@Component({
  selector: 'kenp-dialog',
  templateUrl: 'kenp-dialog.html',
})
export class KenpDialog implements OnInit{
  kenp_rate:string="";
  today = new Date();
  date = this.today.getFullYear()+"-01"+(this.today.getMonth()+1);
  files: any[] = [];
  allowedFileTypes = ['csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];



constructor(private service:HandlerService, public dialogRef: MatDialogRef<KenpDialog>, @Inject(MAT_DIALOG_DATA) public data: any){}

ngOnInit(){
  if(this.data.id){
    this.kenp_rate = this.data.kenp;
    this.date = this.data.time.split(' ')[0];
  }
}
  submit(){
    this.kenp_rate = this.kenp_rate.toString().split("$").join("").split(',').join('.');
    this.service.setKenpRateOfMonth(this.date,this.kenp_rate,this.data.id).subscribe((resp:any)=>{
      console.log(resp)
      if(resp && resp.code && resp.code==200){
        Swal.fire({icon:'success',timer:500})
        this.dialogRef.close({success:true});
      }else{
        Swal.fire({icon:'error',text:'Failed to submit kenp rate: '+resp.message})
      }

    })
  }
  close(){
    this.dialogRef.close({success:false});
  }
  delete(id){
    Swal.fire({icon:'question',text:'you are going to delete KENP rate '+this.data.time.split(' ')[0]+'. Hit yes to proceed.',showConfirmButton:true,showCancelButton:true,confirmButtonText:'YES',cancelButtonText:'NO! cancel..'}).then(resp=>{
      if(resp.value){
        this.service.deleteKenpRateOfMonth(id).subscribe((resp:any)=>{
          console.log(resp)
          if(resp && resp.code && resp.code==200){
            Swal.fire({icon:'success',timer:500})
            this.dialogRef.close({success:true,deleted:id});
          }else{
            Swal.fire({icon:'error',text:'Failed to delete kenp rate: '+resp.message})
          }
    
        })
      }
    })
  }
  dateChanged(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = new Date(event.value);
    let dd = String(date.getDate()).padStart(2, '0');
       let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
       let yyyy = date.getFullYear();
       this.date = yyyy+"-"+mm+"-"+dd;
     console.log(this.date);
   }

   /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      if(!this.allowedFileTypes.includes(item.type)){
        Swal.fire({icon:'warning',text:'Not supported file. Only xls files allowed.'})
        return;
      }
      this.files.push(item);
    }
    let filename = this.files[0];
  }
  uploadFiles(){

    this.service.postFileMonthRoy(this.files[0]).subscribe((resp:any)=>{
      console.log('resp',resp)
      if(resp.code && resp.code==200){
        Swal.fire({icon:'success',text:'Success: '+resp.message,timer:2500}).then(()=>{window.location.reload()})
      }else{
        Swal.fire({icon:'error',text:resp.message}).then(()=>{window.location.reload()})
      }
    })
  }
  clearFiles(){
    this.files=[]
  }
    /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
     formatBytes(bytes, decimals=2) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}

@Component({
  selector: 'estimator-dialog',
  templateUrl: 'estimator-dialog.html',
})
export class EstimatorDialog implements OnInit{
  kenp_rate:string="";
  today = new Date();
  date = this.today.getFullYear()+"-01"+(this.today.getMonth()+1);
  files: any[] = [];
    allowedFileTypes = ['csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  loading:boolean=false;


constructor(private service:HandlerService, public dialogRef: MatDialogRef<KenpDialog>, @Inject(MAT_DIALOG_DATA) public data: any){}

ngOnInit(){
  if(this.data.id){
    this.kenp_rate = this.data.kenp;
    this.date = this.data.time.split(' ')[0];
  }
}
  close(){
    this.dialogRef.close({success:false});
  }

   /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      if(!this.allowedFileTypes.includes(item.type)){
        Swal.fire({icon:'warning',text:'Not supported file. Only xls files allowed.'})
        return;
      }
      this.files.push(item);
    }
    let filename = this.files[0];
  }
  uploadFiles(){
    this.service.postFileEstimator(this.files[0]).subscribe((resp:any)=>{
      console.log('resp',resp)
      if(resp.code && resp.code==200){
        Swal.fire({icon:'success',text:'Success: '+resp.message,timer:2500}).then(()=>{})
      }else{
        Swal.fire({icon:'error',text:resp.message}).then(()=>{})
      }
    },(err:any)=>{
      Swal.fire({icon:'success',text:'File uploaded.',timer:2500}).then(()=>{this.close()})
    })
  }
  clearFiles(){
    this.files=[]
  }
    /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
     formatBytes(bytes, decimals=2) {
      if (bytes === 0) {
        return '0 Bytes';
      }
      const k = 1024;
      const dm = decimals <= 0 ? 0 : decimals || 2;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}




@Component({
  selector: 'expenses-dialog',
  templateUrl: 'expenses-dialog.html',
})
export class ExpensesDialog implements OnInit{
  
  isSearch:boolean=false;
  datefrom:string="";
  dateto:string="";
  constructor(private service:HandlerService, public dialogRef: MatDialogRef<ExpensesDialog>, @Inject(MAT_DIALOG_DATA) public data: any){}
  books: any[]
  authors:any = [];
  divisions:any = [];
  platforms:any = [];
  payees:any = [];
  trainees:any = [];
  notes:any = [];
  ghosts:any=[];
  genres:any=[];
  tropes:any=[];
  bookControl = new FormControl();
  authorControl = new FormControl();
  divisionControl = new FormControl();
  platformControl = new FormControl();
  payeeControl = new FormControl();
  traineeControl = new FormControl();
  notesControl = new FormControl();
  genresControl = new FormControl();
  ghostsControl = new FormControl();
  tropesControl = new FormControl();

  filteredBooks: Observable<string[]>;
  filteredAuthors: Observable<string[]>;
  filteredDivisions: Observable<string[]>;
  filteredPayees: Observable<string[]>;
  filteredTrainees: Observable<string[]>;
  filteredPlatforms: Observable<string[]>;
  filteredNotes: Observable<string[]>;
  filteredGenres: Observable<string[]>;
  filteredGhosts: Observable<string[]>;
  filteredTropes: Observable<string[]>;
  
  ngOnInit(){
    console.log(this.data)
    if(this.data.isSearch){this.isSearch = true;delete(this.data.isSearch)}
    // if(this.data.books){
    //   this.books = ['n/a'];
    //   this.data.books.forEach(b => {
    //       this.books.push(b.book_code);
    //   });
    //   delete(this.data.books)
    // }
    // this.authors = Object.values(HomeComponent.AUTHORS);
    if(this.service.expenses_suggestions){
      if(this.service.expenses_suggestions.books) this.books = this.service.expenses_suggestions.books;
      if(this.service.expenses_suggestions.authors) this.authors = this.service.expenses_suggestions.authors;
      if(this.service.expenses_suggestions.divisions) this.divisions = this.service.expenses_suggestions.divisions;
      if(this.service.expenses_suggestions.platforms) this.platforms = this.service.expenses_suggestions.platforms
      if(this.service.expenses_suggestions.payees) this.payees = this.service.expenses_suggestions.payees;
      if(this.service.expenses_suggestions.trainees) this.trainees = this.service.expenses_suggestions.trainees;
      if(this.service.expenses_suggestions.notes) this.notes = this.service.expenses_suggestions.notes;
      if(this.service.expenses_suggestions.genres) this.genres = this.service.expenses_suggestions.genres;
      if(this.service.expenses_suggestions.ghosts) this.ghosts = this.service.expenses_suggestions.ghosts;
      if(this.service.expenses_suggestions.tropes) this.tropes = this.service.expenses_suggestions.tropes;
      this.filteredBooks = this.bookControl.valueChanges.pipe(startWith(''),map(value => this._filterBooks(value)));
      this.filteredAuthors = this.authorControl.valueChanges.pipe(startWith(''),map(value => this._filterAuthors(value)));
      this.filteredDivisions= this.divisionControl.valueChanges.pipe(startWith(''),map(value => this._filterDivisons(value)));
      this.filteredPayees= this.payeeControl.valueChanges.pipe(startWith(''),map(value => this._filterPayees(value)));
      this.filteredTrainees = this.traineeControl.valueChanges.pipe(startWith(''),map(value => this._filterTrainees(value)));
      this.filteredPlatforms = this.platformControl.valueChanges.pipe(startWith(''),map(value => this._filterPlatforms(value)));
      this.filteredNotes = this.notesControl.valueChanges.pipe(startWith(''),map(value => this._filterNotes(value)));
      this.filteredGenres = this.genresControl.valueChanges.pipe(startWith(''),map(value => this._filterGenres(value)));
      this.filteredGhosts = this.ghostsControl.valueChanges.pipe(startWith(''),map(value => this._filterGhosts(value)));
      this.filteredTropes = this.tropesControl.valueChanges.pipe(startWith(''),map(value => this._filterTropes(value)));
    }
    
    
  }
  fromDate(event){
    this.datefrom = new Date(event.value).toISOString().split('T')[0]
  }
  toDate(event){
    this.dateto = new Date(event.value).toISOString().split('T')[0]
  }
  search(){
    if(this.datefrom.length>0) this.data.DateFrom = this.datefrom;
    if(this.dateto.length>0) this.data.DateTo = this.dateto;
    this.dialogRef.close({search:this.data});
  }
  close(){
    this.dialogRef.close({success:false});
  }
  private _filterBooks(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.books.filter(book => book.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterAuthors(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.authors.filter(author => author.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterDivisons(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.divisions.filter(division => division.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterPayees(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.payees.filter(payee => payee.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterTrainees(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.trainees.filter(trainee => trainee.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterPlatforms(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.platforms.filter(plat => plat.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterNotes(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.notes.filter(not => not.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterGenres(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.genres.filter(not => not.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterGhosts(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.ghosts.filter(not => not.toString().toLowerCase().replace(" ","").includes(filterValue)); }
  private _filterTropes(value: string): string[] { const filterValue = value.toLowerCase().replace(" ",""); return this.tropes.filter(not => not.toString().toLowerCase().replace(" ","").includes(filterValue)); }

  itemSelected(type='book',value){
    console.log('selected',value)
    switch(type){
      case "book": 
          let CODE='';
          if(value=="n/a")this.data.Author=value;
          if(value.includes('-')){
            CODE = value.toString().split('-')[1].trim().substr(0,2);
          }else{
            CODE = value.toString().trim().substr(0,2);
          }
          this.data.Book = value;
          this.data.Author = HomeComponent.AUTHORS[CODE];
      break;
      case "author": this.data.Author = value; break;
      case "division": this.data.Division = value; break;
      case "platform": this.data.Platform = value; break;
      case "payee": this.data.Payee = value; break;
      case "trainee": this.data.Trainee = value; break;
      case "note": this.data.Notes = value; break;
      case "genre": this.data.Genre = value; break;
      case "ghost": this.data.Ghostwriter = value; break;
      case "trope": this.data.Trope = value; break;
    }
    
  }
  clearField(type="bar"){
    (<any>document.querySelector("#"+type)).value="";
    switch(type){
      case "bar": 
        this.bookControl.setValue("");
        this.data.Book='';
      break;
      case "author": 
      this.authorControl.setValue("");
      this.data.Author='';
      break;
      case "division": 
      this.divisionControl.setValue("");
      this.data.Division='';
      break;
      case "platform": 
      this.platformControl.setValue("");
      this.data.Platform='';
      break;
      case "payee": 
      this.payeeControl.setValue("");
      this.data.Payee='';
      break;
      case "trainee": 
      this.traineeControl.setValue("");
      this.data.Trainee='';
      case "note": 
      this.notesControl.setValue("");
      this.data.Notes='';
      case "genre": 
      this.genresControl.setValue("");
      this.data.Genre='';
      case "ghost": 
      this.ghostsControl.setValue("");
      this.data.Ghostwriter='';
      case "trope": 
      this.tropesControl.setValue("");
      this.data.Trope='';
      break;
    }
  }


}